import React from 'react';

function Footer() {

  return (
    <div id='section4' className="rts-footer-two rts-section-gap2Top section" style={{ paddingTop: '100px' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* ,main footer area start */}
            <div className="main-footer-wrapper-two">
              <div
                style={{ flexBasis: 'auto' }}
                className="single-footer-wized-two logo-area"
                data-sal="slide-up"
                data-sal-delay={150}
                data-sal-duration={800}
              >
                <a href="assets/images/logo/02.png" className="logo">
                  <img
                    src="./logowhite.svg"
                    className="main-logo-white"
                    style={{ maxWidth: '300px' }}
                    alt="logo" />
                </a>
                <p className="disc-f">
                  4D Renovation brings your vision to life, transforming homes in all four directions. We turn your dreams into reality, creating a heavenly sanctuary for you to enjoy.
                </p>
                <div className="rts-social-wrapper-three">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-brands fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-brands fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-brands fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div
            className="single-footer-wized-two pages"
            data-sal="slide-up"
            data-sal-delay={250}
            data-sal-duration={800}
          >
            <div className="footer-header-two pages">
              <h6 className="title">Useful Links</h6>
              <div className="pages">
                <ul>
                  <li>
                    <a href="about.html">
                      <i className="fa-solid fa-arrow-right" /> About Us
                    </a>
                  </li>
                  <li>
                    <a href="project.html">
                      <i className="fa-solid fa-arrow-right" /> Our Projects
                    </a>
                  </li>
                  <li>
                    <a href="service.html">
                      <i className="fa-solid fa-arrow-right" />
                      Our Services
                    </a>
                  </li>
                  <li>
                    <a href="team.html">
                      <i className="fa-solid fa-arrow-right" /> Our Team
                    </a>
                  </li>
                  <li>
                    <Link to="/contact">
                      <i className="fa-solid fa-arrow-right" /> Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
              <div
                className="single-footer-wized-two user-number"
                data-sal="slide-up"
                data-sal-delay={350}
                data-sal-duration={800}
              >
                <div className="user-number-wrapper mt--10">
                  {/* single number */}
                  <div className="single-number">
                    <h6 className="title">Phone Number</h6>
                    <div className="number">
                      <i className="fa-solid fa-phone" />
                      <a href="tel:+640210463702">+64 (021) 046 3702</a>
                    </div>
                  </div>
                  {/* single number end */}
                  {/* single number */}
                  <div className="single-number">
                    <h6 className="title">Email address</h6>
                    <div className="number">
                      <i className="fa-light fa-envelope" />
                      <a href="renovations_4d@yahoo.com"> renovations_4d@yahoo.com</a>
                    </div>
                  </div>
                  {/* single number end */}
                  {/* single number */}
                  <div className="single-number">
                    <h6 className="title">Office Location</h6>
                    <div className="number">
                      <i className="fa-light fa-location-dot" />
                      <a className="mt-dec" href="#">
                        Hasting, NewZealand
                      </a>
                    </div>
                  </div>
                  {/* single number end */}
                </div>
              </div>
              {/* <div
            className="single-footer-wized-two newsletter"
            data-sal="slide-up"
            data-sal-delay={550}
            data-sal-duration={800}
          >
            <div className="footer-header-two newsletter">
              <h6 className="title">Newsletter</h6>
              <p className="letters">
                Aplications prodize before front end ortals visualize front end
              </p>
              <form className="subscribe-2-footer">
                <input type="email" required="" placeholder="Email Address" />
                <button className="rts-btn btn-primary">Subscribe Now</button>
              </form>
            </div>
          </div> */}
            </div>
            {/* ,main footer area end */}
          </div>
        </div>
      </div>
      <div className="menu-row-wrapper mt--45">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="menu-wrapper-footer-row">
                {/* single menu wrapper */}
                {/* <div
              className="single-menu"
              data-sal="zoom-in"
              data-sal-delay={150}
              data-sal-duration={800}
            >
              <a href="about.html">About Us</a>
            </div> */}
                {/* single menu wrapper end */}
                {/* single menu wrapper */}
                {/* <div
              className="single-menu"
              data-sal="zoom-in"
              data-sal-delay={150}
              data-sal-duration={800}
            >
              <a href="project.html">Projects</a>
            </div> */}
                {/* single menu wrapper end */}
                {/* single menu wrapper */}
                {/* <div
              className="single-menu"
              data-sal="zoom-in"
              data-sal-delay={150}
              data-sal-duration={800}
            >
              <a href="safety.html">Updates</a>
            </div> */}
                {/* single menu wrapper end */}
                {/* single menu wrapper */}
                {/* <div
              className="single-menu"
              data-sal="zoom-in"
              data-sal-delay={150}
              data-sal-duration={800}
            >
              <a href="vision.html">Mission</a>
            </div> */}
                {/* single menu wrapper end */}
                {/* single menu wrapper */}
                {/* <div
              className="single-menu"
              data-sal="zoom-in"
              data-sal-delay={150}
              data-sal-duration={800}
            >
              <a href="blog-list.html">Inside</a>
            </div> */}
                {/* single menu wrapper end */}
                {/* single menu wrapper */}
                {/* <div
              className="single-menu"
              data-sal="zoom-in"
              data-sal-delay={150}
              data-sal-duration={800}
            >
              <Link to="/contact">Contact</Link>
            </div> */}
                {/* single menu wrapper end */}
                {/* single menu wrapper */}
                {/* <div
              className="single-menu"
              data-sal="zoom-in"
              data-sal-delay={150}
              data-sal-duration={800}
            >
              <a href="company-story.html">History</a>
            </div> */}
                {/* single menu wrapper end */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* copy right area start */}
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="copyright-footer-two">
                <p className="disc">
                  © 2024 4D Renovation. All Right Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* copyright area end */}
    </div>

  );
}

export default Footer;
