import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useRef } from "react";
import { useEffect } from "react";
function Header() {
  const [className, setClassName] = useState('');
 
  const toggleClass = (newClass) => {
    setClassName(newClass);
  };

  const [isSticky, setIsSticky] = useState(false);
  const [activeSection, setActiveSection] = useState('section0');

  useEffect(() => {
    const handleScrollEvent = () => {
      if (window.scrollY > 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      const sections = document.querySelectorAll('.section');
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      let currentId = activeSection;
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        if (scrollPosition >= sectionTop) {
          currentId = section.id;
        }
      });
      setActiveSection(currentId)
    };

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);
  
  const handleScrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      const duration = 800;
      const targetOffset = element.getBoundingClientRect().top + window.pageYOffset;
      const startOffset = window.pageYOffset;
      let startTime = null;

      const scrollStep = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const progress = Math.min((timestamp - startTime) / duration, 1);
        window.scrollTo(0, startOffset + (targetOffset - startOffset) * progress);
        if (progress < 1) window.requestAnimationFrame(scrollStep);
      };

      window.requestAnimationFrame(scrollStep);
    }
  };

  return (
    <div  className="Header " >
      <>
        <header  className="header-one ">
          {/* hedaer top */}
          {/* <div className="header-top">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="header-top-wrapper">
                   
                      <div className="left">
                        <div className="icon">
                          <svg

                            xmlns="http://www.w3.org/2000/svg"
                            width={13}
                            height={17}
                            viewBox="0 0 13 17"
                            fill="none"
                          >
                            <path
                              d="M6.95455 6.95454H12.3636L5.40909 17V10.0455H0L6.95455 -3.8147e-06V6.95454Z"
                              fill="#FF6600"
                            />
                          </svg>
                        </div>
                        <p className="disc">
                          We will go through all the stages of construction
                        </p>
                      </div>
                     
                      <div className="right">
                        <ul>
                          <li className="single">
                            <a href="careers.html">Careers</a>
                          </li>
                          <li className="single">
                            <a href="blog-list.html">News &amp; Media</a>
                          </li>
                          <li className="single last">
                            <a href="contact.html">Contact Us</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          {/* hedaer bottom */}
          {/* header style hear */}
          <div  id="section0" className="header-mid section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="header-mid-one-wrapper">
                    <div className="logo-area">
                      <Link to="/">
                        <img src="./logo3.svg" className="main-logo" alt="logo" />
                      </Link>
                    </div>
                    <div className="header-right">
                      {/* sinle map area */}
                      <div className="single-component info-con">
                        <div className="icon">
                          <i className="fal fa-map-marker-alt" />
                        </div>
                        <div className="info">
                          <span>Our Location</span>
                          <a href="https://maps.app.goo.gl/He12DAPpmiBm1jAP7">Hasting, New Zealand</a>
                        </div>
                      </div>
                      {/* sinle map area */}
                      {/* sinle map area */}
                      <div className="single-component info-con">
                        <div className="icon">
                          <i className="fal fa-envelope" />
                        </div>
                        <div className="info">
                          <span>Send Us Mail</span>
                          <a href="mailto:name@email.com">renovations_4d@yahoo.com</a>
                        </div>
                      </div>
                      {/* sinle map area */}
                      {/* sinle map area */}
                      <div className="single-component">
                        <Link onClick={() => handleScrollToElement('section6')} className="rts-btn btn-primary">
                          Get a Quote
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={14}
                            height={13}
                            viewBox="0 0 14 13"
                            fill="none"
                          >
                            <path
                              d="M11.0039 3.414L2.39691 12.021L0.98291 10.607L9.58891 2H2.00391V-3.8147e-06H13.0039V11H11.0039V3.414Z"
                              fill="white"
                            />
                          </svg>
                        </Link>
                        <div className="menu-area d-block d-lg-none" id="menu-btn">
                          <div className="icon" onClick={() => toggleClass('show')}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={18}
                              height={16}
                              viewBox="0 0 18 16"
                              fill="none"
                            >
                              <rect width={18} height={2} fill="#0C0A0A" />
                              <rect y={7} width={18} height={2} fill="#0C0A0A" />
                              <rect y={14} width={18} height={2} fill="#0C0A0A" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {/* sinle map area */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* header style hearm end */}
         <div className={isSticky ? 'sticky' : ''}> 
          <div className="nav-area-one  header--sticky">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="nav-header-area">
                    <div className="clip-path" />
                    <div className="wrapper">
                      {/* header style two */}
                      {/* nav area start */}
                      <div className="main-nav-desk nav-area">
                        <nav>
                          <ul>
                            <li  className={"menu-item " + (activeSection == 'section0' ? ' current' : '')}>
                              <Link onClick={() => handleScrollToElement('section0')} className="nav-link" to="/">
                                Home
                              </Link>
                
                            </li>
                      
                            <li  className={"menu-item " + (activeSection == 'section1' ? ' current' : '')} >
                              <a onClick={() => handleScrollToElement('section1')} className="nav-link" >
                                Service
                              </a>
                             
                            </li>
                            <li  className={"menu-item " + (activeSection == 'section2' ? ' current' : '')}>
                                <a onClick={() => handleScrollToElement('section2')} className="nav-link" >
                                  Portfolio
                                </a>
                              
                              </li>
                           
                            <li  className={"menu-item " + (activeSection == 'section3' ? ' current' : '')}>
                              <a onClick={() => handleScrollToElement('section3')} className="nav-link">
                                 Contact
                                 </a>
                            </li>
                            <li className={"menu-item " + (activeSection == 'section4' ? ' current' : '')}>
                              <a onClick={() => handleScrollToElement('section4')} className="nav-link" >
                                About Us
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      {/* nav-area end */}
                      {/* header style two End */}
                      {/* <div className="btn-right">
                          <div className="search-btn" id="search">
                            <i className="fa-light fa-magnifying-glass" />
                          </div>
                          <div className="cart-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={21}
                              height={20}
                              viewBox="0 0 21 20"
                              fill="none"
                            >
                              <path
                                d="M18.8153 13.3333V1.90476H20.7201V0H17.8629C17.6104 0 17.3681 0.10034 17.1895 0.278946C17.0109 0.457552 16.9106 0.699794 16.9106 0.952381V12.381H5.06485L3.16009 4.7619H15.0058V2.85714H1.93914C1.79437 2.85715 1.65151 2.89015 1.52141 2.95365C1.39132 3.01715 1.27741 3.10948 1.18834 3.22361C1.09928 3.33774 1.03741 3.47067 1.00743 3.6123C0.977451 3.75392 0.980152 3.90052 1.01533 4.04095L3.39628 13.5648C3.44787 13.7707 3.5668 13.9535 3.73418 14.0841C3.90156 14.2148 4.10778 14.2857 4.32009 14.2857H17.8629C18.1155 14.2857 18.3578 14.1854 18.5364 14.0068C18.715 13.8282 18.8153 13.5859 18.8153 13.3333ZM16.9106 20C17.4157 20 17.9002 19.7993 18.2574 19.4421C18.6147 19.0849 18.8153 18.6004 18.8153 18.0952C18.8153 17.5901 18.6147 17.1056 18.2574 16.7484C17.9002 16.3912 17.4157 16.1905 16.9106 16.1905C16.4054 16.1905 15.9209 16.3912 15.5637 16.7484C15.2065 17.1056 15.0058 17.5901 15.0058 18.0952C15.0058 18.6004 15.2065 19.0849 15.5637 19.4421C15.9209 19.7993 16.4054 20 16.9106 20ZM5.482 20C5.98717 20 6.47165 19.7993 6.82887 19.4421C7.18608 19.0849 7.38676 18.6004 7.38676 18.0952C7.38676 17.5901 7.18608 17.1056 6.82887 16.7484C6.47165 16.3912 5.98717 16.1905 5.482 16.1905C4.97682 16.1905 4.49234 16.3912 4.13513 16.7484C3.77792 17.1056 3.57724 17.5901 3.57724 18.0952C3.57724 18.6004 3.77792 19.0849 4.13513 19.4421C4.49234 19.7993 4.97682 20 5.482 20Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div></div>
        </header>
        {/* header srea end */}
        {/* side bar for desktop */}
        <div id="side-bar" className={`side-bar ${className}`}>
          <button onClick={() => toggleClass('')} className="close-icon-menu">
            <i className="far fa-times" />
          </button>
          {/* inner menu area desktop start */}
          <div className="inner-main-wrapper-desk d-none d-lg-block">
            <div className="thumbnail">
              <img src="assets/images/banner/04.jpg" alt="elevate" />
            </div>
            <div className="inner-content">
              <h4 className="title">
                We Build Building and Great Constructive Homes.
              </h4>
              <p className="disc">
                We successfully cope with tasks of varying complexity, provide
                long-term guarantees and regularly master new technologies.
              </p>
              <div className="footer">
                <h4 className="title">Got a project in mind?</h4>
                <a href="contact.html" className="rts-btn btn-seconday">
                  Let's talk
                </a>
              </div>
            </div>
          </div>
          {/* mobile menu area start */}
          <div className="mobile-menu d-block d-xl-none">
            <nav className="nav-main mainmenu-nav mt--30">
              <ul className="mainmenu" id="mobile-menu-active">
                <li onClick={() => toggleClass('')}>
                  <Link  to="/" className="main">
                    Home
                  </Link>
                 
                </li>
                {/* <li >
                  <a href="#" className="main">
                    Pages
                  </a>
                 
                </li> */}
                <li onClick={() => toggleClass('')}>
                  <a onClick={() => handleScrollToElement('section1')} className="main">
                    Services
                  </a>
              
                </li>
                <li onClick={() => toggleClass('')}>
                    <a onClick={() => handleScrollToElement('section2')} className="main">
                      Portfolio
                    </a>
                    {/* <ul className="submenu">
                      <li>
                        <a className="mobile-menu-link" href="project.html">
                          Project
                        </a>
                      </li>
                      <li>
                        <a className="mobile-menu-link" href="project-details.html">
                          Project Details
                        </a>
                      </li>
                    </ul> */}
                  </li>
                  {/* <li className="has-droupdown">
                    <a href="#" className="main">
                      Blog
                    </a>
                    <ul className="submenu">
                      <li>
                        <a className="mobile-menu-link" href="blog-list.html">
                          Blog List
                        </a>
                      </li>
                      <li>
                        <a className="mobile-menu-link" href="blog-grid.html">
                          Blog Grid
                        </a>
                      </li>
                      <li>
                        <a className="mobile-menu-link" href="blog-details.html">
                          Blog Details
                        </a>
                      </li>
                    </ul>
                  </li> */}
                <li onClick={() => toggleClass('')}>
                  <a onClick={() => handleScrollToElement('section3')} to="/contact" className="main">
                    Contact
                  </a>
                </li>
                <li onClick={() => toggleClass('')}>
                  <a  onClick={() => handleScrollToElement('section5')} className="main">
                    Get Quote
                  </a>
                </li>
                <li onClick={() => toggleClass('')}>
                  <a  onClick={() => handleScrollToElement('section4')} className="main">
                    About us
                  </a>
                </li>

              </ul>
            </nav>
            <div className="social-wrapper-one">
              <ul>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-youtube" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-linkedin-in" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* mobile menu area end */}
        </div>
        {/* header style two End */}
        <div onClick={() => toggleClass('')} id="anywhere-home" className={className} />
      </>

    </div>
  );
}

export default Header;
