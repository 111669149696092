
import React from 'react';
import { useState } from "react";
import toast, { Toaster } from 'react-hot-toast';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    service: '',
    message: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      toast('Processing...');
      const response = await fetch("/request.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      setFormData({
        name: '',
        email: '',
        service: '',
        message: ''
      })
      toast('Request submitted.');
    } catch (error) {
      toast('Request failed.');
      console.error("Error:", error);
    }
  };

  return (
    <>
      {/* BREADCCRUMB */}

      {/* <div className="rts-bread-crumb-area ptb--150 ptb_sm--100 bg-breadcrumb bg_image">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
             
              <div className="breadcrumb-inner text-center">
                <h1 className="title">Contact Us</h1>
                <div className="meta">
                  <a href="#" className="prev">
                    Home /
                  </a>
                  <a href="#" className="next">
                    Contact Us
                  </a>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div> */}

      {/* CONTACT-TYPE */}
      {/* <div className="rts-contact-area-m rts-section-gap">
        <div className="container">
          <div className="row g-24">
          
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="single-contact-one-inner">
                <div className="thumbnail" >
                  <img src="./recent/call3.jpeg" alt="" />
                </div>
                <div className="content">
                  <div className="icone">
                    <img src="assets/images/contact/shape/01.svg" alt="" />
                  </div>
                  <div className="info">
                    <span>Call Us 24/7</span>
                    <a href="tel:+640210463702">
                      <h5>+64 021 046 3702</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
   
          
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="single-contact-one-inner">
                <div className="thumbnail">
                  <img src="./recent/email.jpeg" alt="" />
                </div>
                <div className="content">
                  <div className="icone">
                    <img src="assets/images/contact/shape/02.svg" alt="" />
                  </div>
                  <div className="info">
                    <span>MAke A Quote</span>
                    <a href="mailto: renovations_4d@yahoo.com">
                      <h5>renovations_4d@yahoo.com</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
           
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="single-contact-one-inner">
                <div className="thumbnail">
                  <img src="./recent/location2.jpg" alt="" />
                </div>
                <div className="content">
                  <div className="icone">
                    <img src="assets/images/contact/shape/03.svg" alt="" />
                  </div>
                  <div className="info">
                    <span>Service Station</span>
                    <a href="#">
                      <h5>Hasting, NewZealand.</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div> */}
      {/* CONTACT-FORM */}
      <div style={{ paddingTop: '120px' }} className="rts-contact-page-form-area contact-2 rts-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d98318.73393996374!2d176.7321607039864!3d-39.639351140787625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d6851486a538aeb%3A0x500ef6143a29918!2sHastings%2C%20New%20Zealand!5e0!3m2!1sen!2sin!4v1717837725275!5m2!1sen!2sin"
                width={600}
                height={600}
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div id='section5' className="col-lg-6">
              <div className="mian-wrapper-form">
                <div
                  className="title-mid-wrapper-home-two"
                  data-sal="slide-up"
                  data-sal-delay={150}
                  data-sal-duration={800}
                >
                  <span className="pre">Get In Touch</span>
                  <h2 className="title">Let’s Get in Touch</h2>
                </div>
                <form id="contact" onSubmit={handleSubmit} className="appoinment-form mt--40">
                  <div className="input-half-wrapper">
                    <div className="single-input">
                      <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name" required />
                    </div>
                    <div className="single-input">
                      <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email Address" required />
                    </div>
                  </div>
                  <select style={{ '-webkit-appearance': 'none' }} id="option" name="service" value={formData.option} onChange={handleChange} required >
                    <option value={''} hidden>Select an service</option>
                    <option value={'Renovate/Build Your Home'}>Renovate/Build Your Home</option>
                    <option value={'Repair/Maintenace Your Home'}>Repair/Maintenace Your Home</option>
                    <option value={'Other'}>Other</option>
                  </select>
                  <div className="input-half-wrapper mt--25 ">
                    {/*use thhis above to use mb--30 */}
                    {/* <div className="single-input">
                      <input
                        placeholder="Select Date"
                        type="text"
                        name="checkIn"
                        id="datepicker"
                        defaultValue=""
                        className="calendar"
                        required=""
                      />
                    </div>
                    <div className="single-input">
                      <input type="text" id="timepicker" placeholder="Select Date" />
                    </div> */}
                  </div>
                  <textarea
                    className="form-control mb--30"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Your message Here"
                    required
                    defaultValue={""}
                  />
                  <button type="submit" className="rts-btn btn-primary">
                    SUBMIT MESSAGE
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-center" />
    </>
  );
}

export default Contact;
