import './App.css';
import Home from './pages/Main';
import Header from './pages/Header';
import Footer from './pages/Footer';
import Contact from './Contact';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
function App() {
  return (
    <>


      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>


    </>
  );
}

export default App;
