
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper'
import { Autoplay } from 'swiper/modules';
import React from 'react';
import { Link } from 'react-router-dom';
SwiperCore.use([Autoplay])

function Slider() {


    return (
        <div className="rts-banner-main-area-swiper">
            <>
                {/* banner start */}
             
                    {/* Slider main container */}
                    <Swiper autoplay={{delay: 5000}}>
                      
                       

                        {/* Slides */}
                        <SwiperSlide>
                            {/* rts-banner area end*/}
                            <div className="rts-section-gap ptb_sm-20 rts-banner-one bg_image bg_image--1">
                                <div className="banner-shape-area">
                                    <img
                                        src="assets/images/banner/shape/01.png"
                                        alt="banner-shape"
                                        className="shape shape-1"
                                    />
                                    <img
                                        src="assets/images/banner/shape/02.png"
                                        alt="banner-shape"
                                        className="shape shape-2"
                                    />
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="banner-one-wrapper ptb--110">
                                                <span className="b_one-pre">Welcome You To 4D RENOVATION</span>
                                                <h1 className="title-banner">
                                                Revitalize Your <br /> Space, Realize Your <br /> Dream
                                                </h1>
                                                <div className="button-area-banner">
                                                    <Link to="/contact" className="rts-btn btn-primary">
                                                        Get Started
                                                    </Link>
                                                    {/* call now information */}
                                                    <div className="call-area">
                                                        <div className="icon">
                                                            <i className="fa-regular fa-phone-volume" />
                                                        </div>
                                                        <div className="detail">
                                                            <span>Call Us Now</span>
                                                            <a href="tel:+640210463702" className="number">
                                                                +64 021 046 3702
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* call now information end */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* rts-banner area end */}
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* rts-banner area end*/}
                            <div className="rts-section-gap ptb_sm-20 rts-banner-one bg_image bg_image--2">
                                <div className="banner-shape-area">
                                    <img
                                        src="assets/images/banner/shape/01.png"
                                        alt="banner-shape"
                                        className="shape shape-1"
                                    />
                                    <img
                                        src="assets/images/banner/shape/02.png"
                                        alt="banner-shape"
                                        className="shape shape-2"
                                    />
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="banner-one-wrapper ptb--110">
                                                <span className="b_one-pre">Welcome You To 4D RENOVATION</span>
                                                <h1 className="title-banner">
                                                Fixing Homes, <br /> Restoring Smiles!
                                                </h1>
                                                <div className="button-area-banner">
                                                    <Link to="/contact" className="rts-btn btn-primary">
                                                        Get Started
                                                    </Link>
                                                    {/* call now information */}
                                                    <div className="call-area">
                                                        <div className="icon">
                                                            <i className="fa-regular fa-phone-volume" />
                                                        </div>
                                                        <div className="detail">
                                                            <span>Call Us Now</span>
                                                            <a href="tel:+640210463702" className="number">
                                                            +64 021 046 3702
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* call now information end */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* rts-banner area end */}
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* rts-banner area end*/}
                            <div className="rts-section-gap ptb_sm-20 rts-banner-one bg_image bg_image--3">
                                <div className="banner-shape-area">
                                    <img
                                        src="assets/images/banner/shape/01.png"
                                        alt="banner-shape"
                                        className="shape shape-1"
                                    />
                                    <img
                                        src="assets/images/banner/shape/02.png"
                                        alt="banner-shape"
                                        className="shape shape-2"
                                    />
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="banner-one-wrapper ptb--110">
                                                <span className="b_one-pre">Welcome You To 4D RENOVATION</span>
                                                <h1 className="title-banner">
                                                From Floor to <br /> Roof, Building Dreams <br /> Aloof!
                                                </h1>
                                                <div className="button-area-banner">
                                                    <Link to="/contact" className="rts-btn btn-primary">
                                                        Get Started
                                                    </Link>
                                                    {/* call now information */}
                                                    <div className="call-area">
                                                        <div className="icon">
                                                            <i className="fa-regular fa-phone-volume" />
                                                        </div>
                                                        <div className="detail">
                                                            <span>Call Us Now</span>
                                                            <a href="tel:+640210463702" className="number">
                                                            +64 021 046 3702
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* call now information end */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* rts-banner area end */}
                        </SwiperSlide>
                    

                    </Swiper>
              
                {/* banner end */}
            </>

        </div>
    );
}

export default Slider;
